import React, { useEffect } from "react";
import Buttonredirct from "../../component/button-redirect";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import Abtdepressionimg from "../../assets/img/banner/depression-healer.jpg";
import wrktogtherstrip from "../../assets/img/elements/wrktogtherstrip.png";
import Healerimg from "../../assets/img/gallery/galleryimg3.png";
import Aboutsection from "../../component/aboutsection";
import Singletestimonial from "../../component/singletestimonial";

import Faq from "../../component/faq";
import chkrashealenergy from "../../assets/img/elements/chkrahealenergy1.jpeg";
import Courseprice from "../../component/courseprice";
import faqimg from "../../assets/img/elements/faqleftimg.jpeg";
import "./index.css";
import { useLocation } from "react-router";

const Chkrabanlancingcourse = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // Check if the URL contains the fragment identifier (#faq-section)
    if (location.hash === "#faq-section") {
      const element = document.getElementById("faq-section");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <div>
      <div className="container-fluid margintopall  chakrabalancingbg  px-0">
        <div className="conatctoverlay">
          <div className="container midpartbanner">
            <div className="row">
              <div className="col-sm-8 text-center mx-auto">
                <div>
                  <Typography
                    variant="h3"
                    color="#fff"
                    className="Navpagesheading"
                    fontSize={30}
                  >
                    Balance Your Chakras for Inner Harmony
                  </Typography>
                  <Typography
                    variant="h4"
                    className="Navpagessubheading"
                    color="#fff"
                    mt={3}
                  >
                    Awaken, Align, Heal Energy
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container bigwraper pb-0">
        <Grid container className="innerpadding-all" alignItems={"center"}>
          <Grid item lg={6}>
            <Typography
              color={"#03492f"}
              sx={{
                fontSize: {
                  xs: 24,

                  lg: 30,
                },
              }}
              variant="h3"
            >
              Awaken Your Chakras-The Power of Energy Balancing
            </Typography>
            <br />
            <Typography variant="p" lineHeight={1.2}>
              Chakra Healing is a transformative process that focuses on
              restoring balance and flow to your body's energy centres.
            </Typography>
            <br></br>
            <br></br>
            <Typography variant="p" lineHeight={1.2}>
              By addressing and harmonising the seven primary chakras, this
              healing practice helps release emotional blockages, clear stagnant
              energy, and align your physical, emotional, and spiritual
              well-being. Through various techniques such as guided meditations,
              energy work, and intuitive practices, Chakra Healing empowers you
              to achieve inner peace, enhance your vitality, and foster a deeper
              connection with your true self.
            </Typography>
          </Grid>
          <Grid item lg={6} mt={{ xs: 4 }}>
            <img
              alt=""
              className="chkrasnenergyimg"
              src={chkrashealenergy}
              width={"100%"}
            />
          </Grid>
        </Grid>
        <Grid container className="innerpadding-all my-4">
          <Grid item lg={12}>
            <b class="Bolditalic">Course Overview</b>
            <br></br>
            <Typography variant="p">
              Our Chakra Balancing course is a transformative journey designed
              to restore harmony and energy flow within your body's seven
              primary chakras.
            </Typography>
            <br></br>
            <br></br>
            <Typography variant="p">
              This course guides you through a series of interactive sessions
              and self-paced content, using techniques like guided meditations
              and energy work to release blockages, enhance your emotional and
              physical well-being, and deepen your spiritual connection.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className="container pt-0 bigwraper">
        <div className="row">
          <div className="col-sm-12 mx-auto ">
            <img className="w-100" src={wrktogtherstrip}></img>
            <h6 className="subheadings text-center">Value of the Course:</h6>

            <div className="row ">
              <div className="col-sm-4">
                <ul className="Specialitieslist">
                  <li>
                    <p>Restore Inner Balance</p>
                  </li>
                  <li>
                    <p>Release Energy Blockages</p>
                  </li>
                  <li>
                    <p>Expand Self-Awareness</p>
                  </li>
                </ul>
              </div>
              <div className="col-sm-4 ">
                <ul className="Specialitieslist">
                  <li>
                    <p>Strengthen Emotional Resilience</p>
                  </li>
                  <li>
                    <p>Deepen Spiritual Connection</p>
                  </li>
                  <li>
                    <p>Support Holistic Healing</p>
                  </li>
                </ul>
              </div>
              <div className="col-sm-4 ">
                <ul className="Specialitieslist">
                  <li>
                    <p>Improve Physical Health</p>
                  </li>
                  <li>
                    <p>Boost Mental Clarity</p>
                  </li>
                  <li>
                    <p>Enhances Creativity</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Aboutsection
        img={Healerimg}
        headinglarge="Meet Anu Kulkarni: Your Healer and Therapist for Emotional, Mental, and Spiritual Well-being"
        para2="You’re not trapped in a never-ending cycle; transformation and healing are within your reach. Embrace the potential for liberation that lies within you."
        para3="As a chakra-balancing healer, I offer a heart-centred approach to restoring your spiritual and physical balance through the power of energy work."
        para4="With over 23 years of experience, I have dedicated myself to empowering individuals in our increasingly connected world. I understand the journey you are on and am here to guide you in uncovering the root causes of your challenges. Together, we can create lasting change and help you live your life to its fullest potential."
        button="BOOK A CONSULTATION CALL"
        button2="BOOK A CONSULTATION CALL"
      />
      <Singletestimonial
        para="Anu, my dear friend,Thank you for reaching out when I needed support and going out of of your way for me.I am grateful to have you in my life."
        name="D. Patel"
      />
      <Courseprice />
      <Faq id="faq-section" img={faqimg} />
    </div>
  );
};

export default Chkrabanlancingcourse;
