import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import "./index.css";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { FaRegThumbsUp } from "react-icons/fa6";
import { useNavigate } from "react-router";
const Thankyoupage = ({ buttonname, buttonname2 }) => {
  const redirectToHomepage = () => {
    window.location.href = "/"; // Redirect to the homepage
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="container-fluid margintopall thnkyoubg  px-0  ">
        <div className="conatctoverlay">
          <div className="container midpartbanner ">
            <div className="row ">
              <div className="col-sm-10 text-center mx-auto">
                <div>
                  <FaRegThumbsUp className="thnkmailicon" />
                  <Typography
                    variant="h3"
                    color="#fff"
                    className="Navpagesheading"
                    fontSize={30}
                  >
                    Thank You for Reaching Out!
                  </Typography>
                  <Typography className="text-light">
                    Your message has been sent. You'll receive with your details
                    shortly!
                  </Typography>
                  <Typography
                    variant="h4"
                    className="Navpagessubheading"
                    color="#fff"
                    mt={3}
                  >
                    Looking forward to connecting with you soon!{" "}
                  </Typography>

                  <button
                    href=""
                    onClick={redirectToHomepage}
                    type="button"
                    className=" Allbutton mt-4"
                  >
                    <span class="text">Go Home!</span>
                    <span class="text">Go Home!</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Thankyoupage;
