import React, { useEffect } from "react";
import groupimg from "../../assets/img/banner/banner_contact.jpg";
import pendulum from "../../assets/img/elements/contactimg.png";
import Healerimg from "../../assets/img/gallery/galleryimg3.png";
import Aboutsection from "../../component/aboutsection";
import Form from "../../component/form";
import "./index.css";
import { Typography } from "@mui/material";

const Contact = () => {
  const handleNavLinkClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  useEffect(() => {
    // Scroll to the top when this component is mounted
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs once when the component mounts
  return (
    <div>
      <div className="container-fluid margintopall Contactbanerbg  px-0  ">
        <div className="conatctoverlay">
          <div className="container midpartbanner ">
            <div className="row">
              <div className="col-sm-8 text-center mx-auto">
                <div>
                  <Typography
                    variant="h3"
                    className="Navpagesheading"
                    color="#fff"
                  >
                    We're Here to Support Your Journey – Reach Out Anytime
                  </Typography>
                  <Typography
                    variant="h4"
                    className="Navpagessubheading"
                    color="#fff"
                    mt={2}
                  >
                    Get in Touch with Us
                  </Typography>
                </div>
                <div className="buttonparent text-center ">
                  {/* <Buttonredirct
                    buttonname="BOOK AN APPOINTMENT"
                    buttonname2="BOOK AN APPOINTMENT"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bigwraper px-0">
        <Aboutsection
          img={Healerimg}
          italichead="Hey There"
          para2="If you are considering therapy, then by this point, you must be confused and overwhelmed with just the thought of how it could help you, let alone which path to take. "
          para3="In fact, starting a therapeutic journey itself holds a mix of emotions and several questions "
          para4="I invite you to get started with a free call, an introductory call, in which we can get to know each other if this is a good match for working together. Feel free to bring up whatever is on your mind if and when you're ready, and there is no pressure. If we decide to proceed, then we'll have time for me to learn more about your experiences and needs. "
          para5="Building trust, like all relationships, is a collaborative process. It requires time to develop trust and find our rhythm. Don't you worry, I'll be here guiding and supporting you on your journey. And now, the next step is simple: reach out and take that first step toward healing."
          parahead="I look forward to speaking with you."
          sign="-Anu"
          button="BOOK A CONSULTATION CALL"
        />
      </div>
      <div className="container  px-0">
        <img className="groupimg" src={groupimg} alt=""></img>
      </div>
      <div className="container-fluid bigwraper ">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 mx-auto ">
              <div className="row">
                <div className="col-sm-6 ">
                  <h5 className="title2">CONTACT US</h5>
                  <div className="contactusleftdetails">
                    <Typography>
                      If you have any questions or would like to schedule a
                      session, feel free to call or text me at{" "}
                      <strong>
                        {" "}
                        <a
                          href="tel:+19164679539"
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          +91 9987094405
                        </a>{" "}
                      </strong>
                      Alternatively, you can fill out the contact form below,
                      and I’ll get back to you shortly.
                    </Typography>
                    <Typography>
                      Let’s begin your healing journey together!
                    </Typography>
                    <img
                      src={pendulum}
                      width={"100%"}
                      height={"90%"}
                      alt=""
                   className="contactusleftimg"
                    ></img>
                  </div>
                </div>
                {/* <div className="col-sm-1"></div> */}
                <div className="col-sm-6 ">
                  <div className="p-2">
                    <Form />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Gallerysection /> */}
    </div>
  );
};

export default Contact;
