import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import coursepriceimg from "../../assets/img/elements/coursepriceimg.jpeg";
import "./index.css";
import Buttonredirct from "../button-redirect";
import chkrabalancingbg2video from "../../assets/img/elements/chkrabalancingbg2video.gif";

const Courseprice = () => {
  const data = [
    {
      heading: "I help people just like you, struggling with:",
      list: [
        {
          icon: "⦿",
          label: "Overview of the Seven Chakras ",
        },
        {
          icon: "⦿",
          label: "Releasing Chakra Blockages",
        },
        {
          icon: "⦿",
          label: "Chakra Healing Meditations",
        },
        {
          icon: "⦿",
          label: "Energy Work for Balance",
        },

        {
          icon: "⦿",
          label: "Spiritual Connection",
        },
        {
          icon: "⦿",
          label: "Emotional and Physical Well-being",
        },
      ],
    },
  ];
  return (
    <div className="container-fluid chkracoursepricebg bigwraper">
      <div className="container   ">
        <h4 class="heading-color text-center">Chakra Balancing Course</h4>
        <h3 class="abouthealershort text-center">Pricing Plan</h3>
        <div className="col-sm-10  mx-auto py-4 chakrainnerbg">
          <div className="row mx-0">
            <div className="col-sm-5">
              <img
                src={chkrabalancingbg2video}
                className="coursepriceimg"
                width={100}
              />
            </div>
            <div className="col-sm-7 ">
              <Box sx={{ px: { xs: 0, lg: 4 } }}>
                <Typography variant="h3" fontSize={17} fontWeight={550}>
                  1 Hour Session
                </Typography>
                <Typography
                  fontSize={35}
                  fontWeight={550}
                  variant="h1"
                  my={2}
                  color={"#03492F"}
                >
                  $125 / ₹8000
                </Typography>
                <Typography fontWeight={550}>
                  Wellness & Harmony Framework
                </Typography>
                {data.map((val, i) => (
                  <Box key={i} px={{ lg: 0, xs: 0 }} mb={2}>
                    <List px={{ lg: 0, xs: 2 }}>
                      {val.list.map((value, id) => (
                        <ListItem className="ps-0" key={id}>
                          <ListItemAvatar className="golddots">
                            {value.icon}
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography
                                variant="p"
                                fontSize={{ xs: 13, lg: 15 }}
                              >
                                {value.label}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                ))}
                <div class="buttonparent ">
                  <Buttonredirct
                    buttonname="MAKE AN APPOINTMENT"
                    buttonname2="MAKE AN APPOINTMENT"
                  />
                </div>
              </Box>
            </div>
          </div>
          <div className="row">
            <Typography className="text-center mt-3" variant="p">
              This immersive six-hour healing experience includes Chakra
              Balancing, Aura Reading, and Energy Cleansing. It’s designed to
              rejuvenate your entire being, helping you rediscover inner peace,
              strength, and spiritual clarity.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courseprice;
