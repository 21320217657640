import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Navbar from "../component/navbar";
import Navbarr from "../component/navbar";
import Home from "../views/Home";
import ScrollToTop from "react-scroll-to-top";
import Footer from "../component/footer";
import About from "../views/about-us";
import Gallery from "../views/gallery";
import Testimonial from "../views/testimonial";
import Contact from "../views/contact-us";
import Aboutpage from "../views/about-us";
import Fee from "../views/fee";
import Anxiety from "../views/Anxiety";
import Depression from "../views/Depression";
import Spritualityblockages from "../views/Spritualityblockages";
import Chronicillness from "../views/chronicillness";
import { FaArrowUp } from "react-icons/fa"; // Importing the icon
import Avtarimg from "../assets/img/gallery/galleryimg.png";
import "./index.css";
import Buttonredirct from "../component/button-redirect";
import Footer2 from "../component/footer2";
import Chkrabanlancingcourse from "../views/chkrabalancingcourse";
import Thankyoupage from "../views/thank-you";
const Router = () => {
  return (
    <div>
      <FloatingWhatsApp
        chatMessage="Hello🤝How can we help?"
        phoneNumber="9987094405"
        accountName="ANU THE THERAPIST"
        avatar={Avtarimg}
      />
      <ScrollToTop smooth component={<FaArrowUp />} className="scroll-top" />
      <Navbarr />
      <Routes>
        <Route index path="/" element={<Home />}></Route>
        <Route path="/gallery" element={<Gallery />}></Route>
        <Route path="/testimonial" element={<Testimonial />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/about" element={<Aboutpage />}></Route>
        <Route path="/fee" element={<Fee />}></Route>
        <Route path="/chakrabalancingcourse" element={<Chkrabanlancingcourse/>}></Route>
        <Route path="/anxiety" element={<Anxiety />}></Route>
        <Route path="/depression" element={<Depression />}></Route>
        <Route path="/thank-you" element={<Thankyoupage/>}></Route>
        <Route path="/buttonredirect" element={<Buttonredirct />}></Route>
        <Route
          path="/spiritualityblockages"
          element={<Spritualityblockages />}
        ></Route>
        <Route path="/chronic-illness" element={<Chronicillness />}></Route>
      </Routes>
      {/* <Footer /> */}
      <Footer2 />
    </div>
  );
};

export default Router;
