import React from "react";
import Ecardimg from "../../assets/img/elements/anukulkarni-ecard.jpg";
import { Link } from "react-router-dom";
import "./index.css";
import { Box, List, ListItem, Typography } from "@mui/material";
import { AiFillInstagram } from "react-icons/ai";
import { IoMail } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { FaQuestion } from "react-icons/fa";
import Data from "../../assets/data";
const Ecard = () => {
  return (
    <div className="container-fluid ecardbgparent pt-0 bigwraper">
      <div className="container  e-cardparent text-center pt-3">
        {/* <h5 class="title heading-color mb-4">SERVICES</h5> */}
        <div className="row mx-0">
          <div className="col-sm-4 ps-0">
            <img className="e-cardimg" src={Ecardimg}></img>
          </div>
          <div className="col-sm-8 ps-0">
            <div className="row pt-3 mb-3">
              <h3 className="text-left heading-color mb-0"> Anu kulkarni</h3>
              <span className="e-cardborder">Therapist | Healer | Mentor</span>
            </div>
            <div className="row ecardservicesparent mobecardbgparent ">
              {Data.ecardservices.map((val, i) => (
                <div className="col-sm-6 eacardlistitempading2">
                  <Box key={i} className="">
                    {val.list.map((value, i) => (
                      <List className="ecardlistpadding">
                        {/* <div className="eservicehead">{value.heading}</div> */}
                        <ListItem className=" ecardlisting p-0">
                          <span className="golddots">{value.icon}</span>
                          {value.label}
                        </ListItem>
                      </List>
                    ))}
                  </Box>
                </div>
              ))}
            </div>
          </div>
          <div className="row mb-3">
            <Typography className="eservicehead" variant="p" fontWeight={500}>
              The Chakra Balancing Course - Heal yourself. Heal others too.
            </Typography>
          </div>
        </div>
        <div className="row mt-2 ecardlinks">
          <div className="col-4 ecardiconsparent">
            <a
              href="https://www.instagram.com/anuthetherapist/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="ecardicons">
                <AiFillInstagram />
              </span>
            </a>
            <p>
              <a
                className="mobileiconname"
                href="https://www.instagram.com/anuthetherapist/"
                target="_blank"
                rel="noopener noreferrer"
              >
                @anuthetherapist
              </a>
            </p>
          </div>

          <div className="col-4 ecardiconsparent">
            <a href="mailto:anuakatalyst@gmail.com">
              <span className="ecardicons">
                <IoMail />
              </span>
            </a>
            <p>
              <a
                className="mobileiconname"
                href="mailto:anuakatalyst@gmail.com"
              >
                anuakatalyst@gmail.com
              </a>
            </p>
          </div>

          <div className="col-4 ecardiconsparent ">
            <Link to="/chakrabalancingcourse#faq-section">
              <span className="ecardicons">
                <FaQuestion />
              </span>
            </Link>
            <p>
              <Link
                className="mobileiconname"
                to="/chakrabalancingcourse#faq-section"
              >
                FAQs
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecard;
