import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Data from "../../assets/data";
import logo from "../../assets/img/logo/AnuHealerTherapistLogo.png";
import { Email, LocationOn, Phone } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Bars } from "react-loader-spinner";
const Footer2 = () => {
  const handleRoute = (val) => {
    window.location.href = val;
  };
  const phone = useMediaQuery("(max-width:600px)");

  const location = useLocation();

  const list1 = [
    {
      label: "About",
      url: "/about",
    },
    {
      label: "Chakra Balancing Course",
      url: "/chakrabalancingcourse",
    },
    {
      label: "Testimonials",
      url: "/testimonial",
    },
    {
      label: "Fees",
      url: "/fee",
    },
    {
      label: "Contact",
      url: "/contact",
    },
  ];
  const list2 = [
    {
      label: "Anxiety",
      url: "/anxiety",
    },
    {
      label: "Chronic Illness",
      url: "/chronic-illness",
    },
    {
      label: "Depression",
      url: "/depression",
    },
    {
      label: "Spiritual Blockages",
      url: "/spiritualityblockages",
    },
  ];
  const list3 = [
    // {
    //   label: "Mumbai, India",
    //   icon: <LocationOn />,
    // },
    {
      label: "anuakatalyst@gmail.com",
      icon: <Email />,
    },
    {
      label: "+91 9987094405",
      icon: <Phone />,
    },
  ];

  // newsletter form submission
  const [email, setEmail] = useState(""); // State to hold the email input
  const [error, setError] = useState(""); // State to hold error message
  const [loading, setLoading] = useState(false); // Add loading state
  const [isSubmitting, setIsSubmitting] = useState(false); // State to handle form submission
  const navigate = useNavigate(); // Initialize useNavigate

  // Regular expression for validating email format
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    setError(""); // Clear previous error message

    if (!email) {
      setError("Email is required."); // Set error message for required field
      return;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email address."); // Set error message for invalid email
      return;
    }

    // If validation passes, submit the form to Web3Forms
    setIsSubmitting(true); // Indicate that form is submitting
    setLoading(true); // Start loading

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          access_key: "26cc9ba3-3838-4e33-bfbf-5de20864a633", // Replace with your Web3Forms access key
          email: email,
        }),
      });

      const result = await response.json();
      setLoading(false); // Stop loading
      if (result.success) {
        // If the submission is successful, clear the email and redirect
        setEmail(""); // Clear the email input
        navigate("/thank-you"); // Redirect to thank-you page
        toast.success("Subscribed successfully!"); // Show success toast
      } else {
        setError("An error occurred. Please try again."); // Handle any errors from Web3Forms
      }
    } catch (error) {
      setLoading(false); // Stop loading
      setError("An error occurred. Please try again."); // Handle network errors
    } finally {
      setIsSubmitting(false); // Reset submission state
    }
  };
  // end newslettr form submission

  const handleRouter = (url) => {
    navigate(url);
  };

  return (
    <Box sx={{ px: { xs: 1 }, pt: 4, borderTop: "1px solid #d7d7d7" }}>
      <div className="container">
        <Stack
          direction={{ lg: "row", xs: "column" }}
          alignItems={{ lg: "center" }}
          justifyContent={"space-between"}
          mb={0}
          // p={2}
        >
          <Box>
            <Typography
              fontSize={{ lg: 25, xs: 15 }}
              fontWeight={550}
              letterSpacing={1.3}
              sx={{
                margin: "0 !important",
              }}
            >
              Sign Up to Newsletter
            </Typography>
            <Typography
              fontSize={13}
              fontWeight={500}
              lineHeight={1}
              sx={{
                margin: "0 !important",
              }}
              mt={{ xs: 2 }}
            >
              Join the monthly newsletter to receive meditations on life and
              affirmations to inspire + heal.
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Stack
              direction={{ lg: "row", xs: "column" }}
              alignItems={"baseline"}
              spacing={{ lg: 4, xs: 2 }}
              mt={{ xs: 3 }}
            >
              <TextField
                placeholder="Enter Your Email Address"
                sx={{ width: { lg: 300 } }}
                fullWidth // Make the TextField full width
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Update email state on input change
                error={Boolean(error)} // Set error state for TextField
                helperText={error} // Show error message below the TextField
              />
              <button className="Allbutton" type="submit">
                {loading ? (
                  <div>
                    <Bars
                      height="20"
                      width="70"
                      radius="9"
                      color="green"
                      ariaLabel="loading"
                      wrapperStyle
                      wrapperClass
                    />
                  </div>
                ) : (
                  <>
                    <span className="text">Subscribe</span>
                    <span className="text">Subscribe</span>
                  </>
                )}
              </button>
            </Stack>
          </form>
        </Stack>
        <Grid container>
          <Grid
            item
            lg={4}
            xs={12}
            pl={0}
            pr={4}
            sx={{
              pt: { lg: 4, xs: 4 },
              pb: { lg: 4, xs: 2 },
            }}
          >
            <div className="mobileviewicons">
              <div
                className="d-flex "
                style={{ justifyContent: "space-between" }}
              >
                <Link to={"/"}>
                  <img
                    alt=""
                    className="anukulkarnimg "
                    src={logo}
                    width={100}
                  />
                </Link>
                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                  {Data.socialIcons.map((val, i) => (
                    <IconButton
                      sx={{
                        fontSize: 20,
                        ":hover": {
                          color: "#fff",
                          backgroundColor: val.bgColor,
                        },
                        transition: " 0.5s ease all",
                      }}
                      key={i}
                      onClick={() => handleRoute(val.url)}
                    >
                      {val.icon}
                    </IconButton>
                  ))}
                </Stack>
              </div>
              <Typography className="mb-0" fontSize={13}>
                Anu Kulkarni is Spiritual Healer and Holistic Therapist,
                providing online and in-person therapy aid to individuals of all
                age groups.
              </Typography>
            </div>
            <div className="desktopviewicons">
              <div>
                <Link to={"/"}>
                  <img
                    alt=""
                    className="anukulkarnimg "
                    src={logo}
                    width={100}
                  />
                </Link>
                <Typography fontSize={13}>
                  Anu Kulkarni is Spiritual Healer and Holistic Therapist,
                  providing online and in-person therapy aid to individuals of
                  all age groups.
                </Typography>
              </div>

              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                {Data.socialIcons.map((val, i) => (
                  <IconButton
                    sx={{
                      fontSize: 20,
                      ":hover": {
                        color: "#fff",
                        backgroundColor: val.bgColor,
                      },
                      transition: " 0.5s ease all",
                    }}
                    key={i}
                    onClick={() => handleRoute(val.url)}
                  >
                    {val.icon}
                  </IconButton>
                ))}
              </Stack>
            </div>
          </Grid>

          <Grid item lg={4} xs={12} sx={{ p: { xs: 0, lg: 4 } }}>
            <List>
              {list1.map((val, i) => (
                <ListItemButton
                  className="listitempading"
                  key={i}
                  onClick={() => handleRouter(val.url)}
                >
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          margin: "2px !important",
                          fontSize: 15,
                        }}
                      >
                        {val.label}
                      </Typography>
                    }
                  />
                </ListItemButton>
              ))}
            </List>
          </Grid>

          <Grid item lg={4} xs={12} sx={{ p: { xs: 0, lg: 4 } }}>
            <List>
              <Typography
                fontWeight={550}
                fontSize={20}
                p={2}
                sx={{ margin: "0px !important" }}
                 className="listitempading"
              >
                Contact
              </Typography>
              {list3.map((val, i) => (
                <ListItem       className="listitempading" key={i}>
                  <ListItemAvatar sx={{ minWidth: 20 }}>
                    {val.icon}
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      val.icon.type === Email ? (
                        <a
                          href={`mailto:${val.label}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <Typography
                            sx={{
                              margin: "5px !important",
                              fontSize: 15,
                            }}
                          >
                            {val.label}
                          </Typography>
                        </a>
                      ) : val.icon.type === Phone ? (
                        <a
                          href={`tel:${val.label}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <Typography
                            sx={{
                              margin: "5px !important",
                              fontSize: 15,
                            }}
                          >
                            {val.label}
                          </Typography>
                        </a>
                      ) : (
                        <Typography
                          sx={{
                            margin: "5px !important",
                            fontSize: 15,
                          }}
                        >
                          {val.label}
                        </Typography>
                      )
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
        <Divider sx={{ border: "1px solid #000" }} />
        <Stack
          direction={{ lg: "row", xs: "column" }}
          alignItems={{ lg: "center", xs: "flex-start" }}
          justifyContent={"space-between"}
        >
          <Typography fontSize={13}>
            © {new Date().getFullYear()}, AnuTheTherapist | Holistic Therapist &
            Holistic Healer | Virtual Healing Counseling & Online Therapy in
            Global
          </Typography>
          <Typography fontSize={12}>
            Made by{" "}
            <a
              href="https://www.digixito.com/"
              style={{ textDecoration: "none", color: "black" }}
            >
              Digixito
            </a>{" "}
            ❤
          </Typography>
        </Stack>
      </div>
    </Box>
  );
};

export default Footer2;
