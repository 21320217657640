import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./index.css";
import Data from "../../assets/data";

const Faq = (props) => {
  return (
    <div id={props.id}>
      <div className="container pb-5">
        <h5 class="title text-center heading-color mb-5">
          Frequently Asked Questions
        </h5>
        <section className="row align-items-center  ">
          <div className="col-sm-5 ">
            <div className="innerpadding-all">
              <img className="faqimg" src={props.img}></img>
            </div>
          </div>
          <div className="col-sm-7">
            <div className="innerpadding-all">
              <Accordion>
                {" "}
                {/* Set the defaultActiveKey to the one you want to open initially */}
                {Data.faq.map((val, id) => (
                  <Accordion.Item
                    eventKey={id.toString()}
                    key={id}
                    className="faqacitem"
                  >
                    <Accordion.Header className="faqheader">
                      {val.question}
                    </Accordion.Header>
                    <Accordion.Body className="fqaanswer">
                      {val.answer}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Faq;
