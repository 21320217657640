import React, { useEffect } from "react";
import Modalitiesimage from "../../assets/img/elements/modalitiesbgimg.jpg";
import wrktogtherstrip from "../../assets/img/elements/wrktogtherstrip.png";
import pendulum from "../../assets/img/gallery/galleryimg2.jpg";
import Healerimg from "../../assets/img/gallery/galleryimg3.png";
import Aboutsection from "../../component/aboutsection";
import Buttonredirct from "../../component/button-redirect";
import Contactsection from "../../component/contactformsection";
import Gallerysection from "../../component/gallerysection";
import Modalities from "../../component/modal";
import Singletestimonial from "../../component/singletestimonial";
import Worktogether from "../../component/wrktogether";
import "./index.css";
import { useMediaQuery } from "@mui/material";
const Aboutpage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const phone = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <div className="bigwraper"></div>
      <div className="container-fluid px-0  ">
        <Aboutsection
          img={Healerimg}
          subheading="Anu Healer Therapist"
          para=" Holistic Healing Therapy for releasing all blockages limiting growth for self growth and Bliss consciousness!"
          para1="All Ways remember YOU are worth it!"
          // italichead2=" Hi there!"
          para2="As an experienced mind, and body practitioner, healer and founder of the ‘Daily Energy Bath’, self-mastery course. My practices are deeply rooted in cross-cultural modalities of energy work, mindfulness, integrative pendulum, hypnotherapy, and various modalities to guide you into an energetic force field of magnificence and holistic well-being."
          para3="My approach is tailored to your personal requirements that authenticates and enhances your true inner potential to be expressed effortlessly with confidence and power never ever imagined to be possible. With more than 23+ years, I facilitate others to have a perpetual FEEL good energy towards all aspects of their motivations manifesting into a reality. By releasing all boxes of beliefs that have created discomfort expressed as anxiety, depression, traumas blocks in relationships and anything considered incurable or impossible becomes possible. The key to progress is to release all blockages across all aspects of your soul across timelines done through "
          strongpara2="soul healing, past life regression, hypnotherapy, pendulum healing and reading, Chakra and Aura reading and Akashic reading, and more...
"
          para4="I believe that we are the divine creations, made from one source of Creation! We are all part of this incredible force field of Cosmic Oneness! Let me help you re-discover your True Self!!"
        />
        <div className="buttonparent text-center">
          <Buttonredirct
            buttonname="Book A Consultation Call"
            buttonname2="Book A Consultation Call"
          />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 mx-auto">
            <img className="w-100" src={wrktogtherstrip} alt="" />
            <h6 className="subheadings text-center heading-color">
              Release All Issues in your Tissues:
            </h6>

            <div className="row">
              <div className="col-sm-4">
                <ul className="Specialitieslist">
                  <li>
                    <p>Loneliness.</p>
                  </li>
                  <li>
                    <p>Relationship Conflicts.</p>
                  </li>
                  <li>
                    <p>Unresolved Past Life Traumas/blocks.</p>
                  </li>
                </ul>
              </div>
              <div className="col-sm-4">
                <ul className="Specialitieslist">
                  <li>
                    <p>
                      Lack of Purpose, Feeling Uncertain in Career/Professional
                      Goals.
                    </p>
                  </li>
                  <li>
                    <p>Depression, Fatigue, Anxiety, Stress.</p>
                  </li>
                </ul>
              </div>
              <div className="col-sm-4">
                <ul className="Specialitieslist">
                  <li>
                    <p>
                      Career, Business Growth, Money Blocks. Property Sale
                      Blockages/Space Healings.
                    </p>
                  </li>
                  <li>
                    <p>
                      Cure Any Disease Physical, Mental Emotional or Spiritual.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {!phone && (
        <Modalities
          bgImage={Modalitiesimage}
          height={"100vh"}
          modalitieshead="Orientation + Modalities"
          list1="Energy Work and Clearing"
          list2="Pendulum Healing"
          list3="Hypnotherapy"
          list4="Meditation"
          list5="Reconnective Healing"
        />
      )} */}
      <div className="container text-center">
        <div className="col-sm-12 mx-auto">
          <div className="row">
            <img className="w-100" src={wrktogtherstrip} alt="" />
            <h4 className="heading-color">About Me</h4>
            <div className="col-sm-12 spaceblock pt-3 aboutmeparent">
              <div className=" innerpadding-all">
                <p>
                  Healing is a journey that evolved through a spiritual quest of
                  self-discovery. To understand Who am I and what is my purpose
                  for being here. The path to sharing this phenomenal, magical
                  journey became my mission to share and enable others to grow
                  in leaps and bounds that I experienced. My exposure to such
                  profound understandings can help others to benefit in the
                  shortest period of time. When joy becomes your true essence
                  then it feels good to enable others to experience it too.
                  Specially committed to assisting people in releasing deep
                  emotional and spiritual understanding for more than two
                  decades. It truly has been a privilege since 2002 to witness
                  phenomenal transformations in clients in just a few sessions.
                </p>
                <p>
                  Though based in Mumbai, I offer personal online sessions. My
                  healing consists of periodic personal sessions and most of the
                  healing work is done offline which is explained in my
                  consultations.
                </p>
              </div>
            </div>
            <hr className="aboutpagehr"></hr>
          </div>
          <div className="row mt-3">
            <div className="col-sm-6 content-justify">
              <div className="innerpadding-all">
                <h4 className="heading-color">Background</h4>
                <p>
                  My professional journey as a healer started in 2002 when I
                  joined the pioneer batch of Hypnotherapists Certified by The
                  California Institute of Hypnosis pioneered by (Late) Dr. Sunny
                  Satin in Mumbai. Little did I know what an enriching and
                  spiritually enlightening journey this would become for me.
                  Specialisation in Hypnotherapy and Past Life Regression
                  brought scores of clients out of deep-rooted emotional traumas
                  and surprising spiritual awakenings. Every session actually
                  broadened my perspective of life itself. Later, in 2016, I
                  fortified my skills by being Certified under Dr. Eric Pearl as
                  a Reconnective Healing Practitioner followed by an advanced
                  healing, The Reconnection. This further deepened my healing
                  with even more expertise in different practices of healing
                  such as the Akashic Record readings, the Miracle method with
                  the pendulum, Theta healing and many more that enhanced
                  aligning the lives of my clients. My initial journey
                  kickstarted as a business partner in a popular boutique called
                  Zingari. I am now a co-founder of Ayurveda products of Nirwana
                  Wellness 2010. Dabbled also in marketing an all-purpose
                  gourmet masala mixes from P.K. Foods since 2015.
                </p>
                <p>
                  The combination of therapeutic practice with business acumen
                  helped enrich my career, facilitating an all-around approach
                  towards Holistic Wellness.
                </p>
              </div>
            </div>
            <div className="col-sm-6 content-justify">
              <div className="innerpadding-all">
                <h4 className="heading-color">My Holistic Philosophy</h4>
                <p>
                  As a professional with over 23 years of experience, my
                  wholistic practice embraces and honours my client's journey to
                  release blockages in their path to bring in a clear and clean
                  state of being to manifest anything they aspire for at all
                  levels be it emotional, mental, spiritual or otherwise. Since
                  2002, I have successfully used hypnotherapy and Past Life
                  Regression for deeply ingrained issues, yielding powerful
                  changes in just a few sessions. Reconnective Healing is a
                  powerful energy practice that reinstates balance and energy at
                  a deeper level. When we visit a doctor to take care of our
                  illness, we are given various combinations of medication to
                  heal. Similarly, an integrated combination of different
                  modalities accelerates healing at deep levels of our
                  consciousness.
                </p>
                <p>
                  {" "}
                  A special roadmap is created exclusively to heal your needs.
                  Every session focuses on bringing you closer to complete inner
                  transformation for a deeper level of balance and fulfilment
                  that guides you toward inner peace and joy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Gallerysection />
      <Singletestimonial
        para="Anu it’s all thanks to you . You gave me a lot of strength and courage by working on me so  deligently every day .
      That encouraged me to come for the wonderful workshop you had for us.
Anu always be blessed."
        name="S. Nair"
      />
      {/* <Worktogether /> */}
      <Contactsection
        img={pendulum}
        heading="Ready to Begin Your Healing Journey?"
        para="There’s no better time than now to start your healing process. Click below to book your session,"
        paraa="or reach out at "
        mail="anuakatalyst@gmail.com"
        continuepara="for more details. I look forward to connecting with you and supporting your journey toward inner harmony and transformation."
        button="MAKE AN APPOINTMENT"
        button2="MAKE AN APPOINTMENT"
      />
    </div>
  );
};

export default Aboutpage;
