import servicesimg1 from "../assets/img/elements/testimonialperson1.jpg";

import humanbody from "../assets/img/elements/humanbody.jpeg";
import humanbody2 from "../assets/img/elements/humanbody2.jpeg";
import { FaArrowRight } from "react-icons/fa";
import humanaura from "../assets/img/elements/humanaura.jpg";
import testimonialperson1 from "../assets/img/elements/testimonialperson1.jpg";
import anxiety from "../assets/img/elements/Anxiety.png";
import Chronicillness from "../assets/img/elements/chronicillnessimg.png";
import depression from "../assets/img/elements/depression.png";
import sprituality from "../assets/img/elements/spritualityimg.png";
import galleryimg1 from "../assets/img/gallery/galleryimg1.jpg";
import galleryimg2 from "../assets/img/gallery/galleryimg2.jpg";
import galleryimg8 from "../assets/img/gallery/galleryimg8.jpeg";


import galleryimg3 from "../assets/img/gallery/galleryimg3.png";
import galleryimg4 from "../assets/img/gallery/galleryimg4.jpg";
import galleryimg5 from "../assets/img/gallery/galleryimg5.jpg";
import galleryimg6 from "../assets/img/gallery/galleryimg6.jpg";
import packagebg from "../assets/img/elements/packagebg.png";
import { FaFacebookF, FaLinkedin, FaTrophy } from "react-icons/fa6";
import { CgDollar } from "react-icons/cg";
import { MdOutlineStarPurple500 } from "react-icons/md";
import { HiLightBulb } from "react-icons/hi";
import { FaInstagram } from "react-icons/fa";
import { FaCrown } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import clientpendulum from "../assets/img/gallery/Client-pendulum.jpeg";
import galleryvideo1 from "../assets/img/gallery/galleryvideo1.mp4";
import testimonialperson from "../assets/img/elements/testimonialperson1.jpg";
import testimonialvideoimg from "../assets/img/elements/testimonialvideoimg1.jpg";
import { MdStarRate } from "react-icons/md";
import { BsYoutube } from "react-icons/bs";
import { TiSocialLinkedin } from "react-icons/ti";

let Data = {
  coursestepsleft: [
    {
      no: "01",
      name: "Energy Clearing",
    },
    {
      no: "03",
      name: "Pendulum Therapy",
    },
    {
      no: "05",
      name: "Chakra Healing",
    },
  ],
  coursestepsright: [
    {
      no: "02",
      name: "Aura Reading",
    },
    {
      no: "04",
      name: "Life Empowerment",
    },
    {
      no: "06",
      name: "Soul Realignment",
    },
  ],
  about: [
    {
      name: "Years of Experience",

      icon: <IoIosCheckmarkCircle className="custom-check me-3" />,
    },
    {
      name: "Experts Spritual Healer",

      icon: <IoIosCheckmarkCircle className="custom-check me-3" />,
    },
    {
      name: "Flexible Schedule",

      icon: <IoIosCheckmarkCircle className="custom-check me-3" />,
    },
  ],
  services: [
    {
      name: "Hypnotherapy",
      img: servicesimg1,
      para: "Ainibus metus elit tempus …",
      icon: <FaArrowRight />,
    },
    {
      name: "Past Life Regression",

      para: "Ainibus metus elit tempus faucibus…",
      icon: <FaArrowRight />,
    },
    {
      name: "Akashik Record Reading",

      img: humanbody,
      para: "Ainibus metus elit tempus faucibus…",
      icon: <FaArrowRight />,
    },
    {
      name: "Reconnective Healing",

      img: humanbody2,
      para: "Ainibus metus elit tempus faucibus…",
      icon: <FaArrowRight />,
    },
    {
      name: "Pendulum Healing",

      img: servicesimg1,
      para: "Ainibus metus elit tempus faucibus…",
      icon: <FaArrowRight />,
    },
  ],
  insta: [
    {
      icon: <FaInstagram color="#fff" />,
      url: "https://www.instagram.com/p/C7MN0c_SX2V/?igsh=MTVzM3M5emY2OWtlMg==",
    },
    {
      icon: <FaInstagram color="#fff" />,
      url: "https://www.instagram.com/reel/C6nY777hC3D/?igsh=enphYXJ0eWJmaWht",
    },
    {
      icon: <FaInstagram color="#fff" />,
      url: "https://www.instagram.com/p/C6ad9EJB3mR/?igsh=aXU2Z21rYTJwMXN4",
    },
    {
      icon: <FaInstagram color="#fff" />,
      url: "https://www.instagram.com/reel/C6If8eSBXGG/?igsh=Ympma3NyYzF6YWp5",
    },
    {
      icon: <FaInstagram color="#fff" />,
      url: "https://www.instagram.com/reel/C6If8eSBXGG/?igsh=Ympma3NyYzF6YWp5",
    },
    {
      icon: <FaInstagram color="#fff" />,
      url: "https://www.instagram.com/reel/C6If8eSBXGG/?igsh=Ympma3NyYzF6YWp5",
    },
  ],
  specialitiesImage: [
    {
      img: anxiety,
    },
    {
      img: Chronicillness,
    },
    {
      img: depression,
    },
    {
      img: sprituality,
    },
  ],
  gallery: [
    {
      img: galleryimg1,
      para: "Loream",
      heading: "Consultation",
    },
    {
      img: clientpendulum,
      para: "Loream",
      heading: "Health",
    },
    {
      img: galleryimg8,
      para: "Loream",
      heading: "Health",
    },
  ],

  faq: [
    {
      question: "What is Chakra Balancing and how does it work?",
      answer:
        "Chakra Balancing is a holistic practice that restores the natural energy flow within the body’s seven main energy centers. It involves techniques like meditation, visualization, and energy healing to clear blockages, promoting emotional, physical, and spiritual well-being.",
    },
    {
      question: "Is this course suitable for beginners?",
      answer:
        "Yes, the course is designed for all levels. Beginners will receive foundational knowledge on chakras, while more experienced participants can deepen their understanding and enhance their practices.",
    },
    {
      question: "What tools or materials do I need for this course?",
      answer:
        "You only need an open mind, a quiet space for practice, and access to online resources. A journal is recommended for self-reflection during the course.",
    },
    {
      question: "How will the online sessions be conducted?",
      answer:
        "The online sessions will be interactive, using video conferencing tools. Participants will engage in guided meditations, energy healing techniques, and Q&A sessions with the instructor.",
    },
    {
      question: "What are the benefits of balancing my chakras?",
      answer:
        "Balancing chakras can help improve emotional stability, mental clarity, physical vitality, and deepen your spiritual awareness, leading to overall harmony and well-being.",
    },
  ],
  packages: [
    {
      imgbg: packagebg,
      img: humanbody,
      nameicon: <HiLightBulb />,
      name: "Basic",
      icon: <CgDollar />,
      time: "20 min",
      price: " ₹1500 / $20",
      para: "Connect a call ",
      whtsinclude: "What's included?",
      btntext: "Book Now",
      list: [
        {
          included: "Ask 3-4 questions ",
        },
        {
          included: "Introduction to spiritual healing",
        },
        {
          included: "Clear Away All Doubts",
        },
      ],
    },
    {
      imgbg: packagebg,
      img: humanbody,
      name: "Standard",
      nameicon: <MdOutlineStarPurple500 />,
      icon: <CgDollar />,
      price: "$55 / ₹4500 ",
      time: "1/2 hour",
      para: "Get an expert psychic reading ",
      whtsinclude: "What's included?",
      btntext: "Book Now",
      list: [
        {
          included: "Pendulum Reading",
        },

        {
          included:
            "Ask Any Questions related to life, love, relationships, mental health,.etc.",
        },
        {
          included: "More Accurate Than Tarot",
        },
      ],
    },
    {
      imgbg: packagebg,
      img: humanbody,
      name: "Premium",
      nameicon: <FaCrown />,
      icon: <CgDollar />,
      price: "$125 / ₹8000",
      time: "1 hour",
      para: "Heal for a Better Life ",
      whtsinclude: "What's included?",
      btntext: "Book Now",
      list: [
        {
          included: "Clearing Energy & Blockages",
        },
        {
          included: "Holistic Therapy & Chakra Healing",
        },

        {
          included: "Discover Root Cause of Suffering",
        },
      ],
    },
    // {
    //     imgbg:packagebg,
    //     img: humanbody,
    //     name: "Enterprise",
    //     nameicon: <FaTrophy />,
    //     icon: <CgDollar />,
    //     price: 250,
    //     para: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit",
    //     whtsinclude: "What's included?",
    //     btntext: "Enroll Now",
    //     list: [
    //       {
    //         included: "Free Consultation",
    //       },
    //       {
    //         included: "professional trainer",
    //       },
    //       {
    //         included: "Free Consultation",
    //       },
    //     ],
    //   },
  ],
  gallerypage: [
    {
      img: galleryimg1,
    },
    {
      img: clientpendulum,
    },
    {
      img: galleryimg2,
    },
    {
      img: galleryimg3,
    },
    {
      img: galleryimg2,
    },
    {
      img: galleryimg5,
    },
    {
      img: galleryimg6,
    },
    {
      img: galleryimg5,
    },
    {
      img: galleryimg6,
    },
    // {
    //   img: galleryimg1,

    // },
  ],
  testimonialpage: [
    {
      para: "Anu What better then this testimony kudos keep up with your work ....you really go deep and resolve the issues great going",
      name: "Aarav S.",
    },
    {
      para: "Thank you. Today was indeed special. I’m glad I was there . It was a brand new learning.",
      name: "D. Patel",
    },

    {
      para: " Anu it’s all thanks to you . You gave me a lot of strength and courage by working on me so  deligently every day .That encouraged me to come for the wonderful workshop you had for us.Anu always be blessed.",
      name: "P. Desai",
      img: testimonialperson,
      heading: "I've found my sanctuary at this yoga studio ",
      url: "../assets/img/elements/testimonialvideoimg1.jpg",
    },
    {
      para: "Yes anu I agree with all. You are always ready to help everyone. You helped my daughter and me cleared so many of our questions. Helped us to stay positive. You helped us clear so much of our negative energy which even we didn’t know about. Thank you Anu so much.  ",
      name: "Sanjana K.",
    },
    {
      para: "I’d like to strongly recommend Anu for the incredible changes she has brought about in various aspects of my life. These aspects include addressing relationship issues, facilitating command and perspective of my life, providing clarity of vision, attaining self-love and facilitating goal attainment. As a result of these changes, I am a new person.",
      name: "S. Khanna",
    },
    {
      para: "All these wonderful messages.Yes Anu is truly amazing.She has been a true friend mentor guide n a shining bright light to show the way even if she has to 'knock' it in Hahaha speaking of myself here . Shes always.asking n pushing me to look inside for the answers to become who I truly am n what I can be !!!Thanks  always Anu for being there for me ",
      name: "P. Joshi",
    },

   
    {
      para: "I was completely distraught with my relationship with my mother in law. After marriage I moved to Paris and my husband couldn’t manage to keep us both happy. Tired with the situation, he asked me to leave if I wished.I felt helpless and betrayed.Reaching out in desperation to Anu, I couldn’t believe the change within me in just few hours. My life turned 360degrees.When she changed the way I was dealing with my trauma, my problem solved magically.I’m so happy thanks to you Anu !Spread your kindness and your support will never be forgotten! ",
      name: "Priya K.",
    },
    {
      para: "It has taken me some time to process everything after my first distance healing session with Anu. Anu you are a magical being, without even meeting in person you were able to heal my long prolonged throat pain and persistent cough. Your energy is powerful, so strong, gentle, feminine and so loving. I felt very safe. You've helped me remove the blockages that were not allowing me to heal. You helped me heal - my energy levels were amazing all of a sudden & I felt so much lighter in just 10 minutes…it was beautiful ! Thank you, thank you & thank you 🙌🏻I highly recommend Anu, she is very knowledgable in what she does and you will feel instant connection to her. She's pure magic!",
      name: "Vanditta Malhotra Hegde, Lawyer",
    },
    {
      para: " Hi, Anu! You had asked me to do the exercise of loving and forgiving myself, on an hourly basis? Yesterday, when I first started, it was absolutely mechanical. I could feel nothing.But since this morning, I could feel my emotions adding up to my words, on their own.As my alarm would ring, I was in kitchen several times, and like a prayer ritual, or a medicine that needs to be taken, I started speaking to myself, words of love and forgiveness.Don’t know if I am getting healed, but that sanity, which seemed missing, is returning. I am liking it!God bless you! ",
      name: "Sunita Manchanda",
    },
  ],
  testimonialsection: [
    {
      name: "- A. Sharma",
      para: "I am immensely grateful for my journey with Anu. The past many months have been nothing short of amazing. She has skillfully cleared what seemed to be past life and generational blockages, doing so with remarkable dedication, care, love, kindness, clarity, and thoughtfulness. The energy she exudes is as inspiring as it is endearing. Each session leaves me feeling lighter, more centered, profoundly at peace and ever hopeful. I highly recommend her services to anyone seeking deep, transformative healing.Very gratefully",
    },

    {
      name: "- B. Mehta",
      para: "Anu is truly amazing.. She has been a true friend, mentor, guide and a shining bright light to show the way ..even if she has to 'knock' it in…Hahaha ..speaking of myself here. She's always asking and pushing me to look inside for the answers .... to become who I truly am and what I can be!!! Thanks, always Anu... for being there for me…Love you.",
    },
    {
      name: "- D. Gupta",
      para: "Today to me actually feels like Rebirthing with learning of such a unique amd simple Pendulum self-mastery course.. It actually justifies the title. It is sooo interesting yet so simple for us to practice on our own. it's actually one of its kind... the Material that Anu provides with this course is so well curated that it just makes this course a cakewalk...i would really recommend anyone who wants to become self empowered with their healing to join in ...Superrrrrr cool Anu really had a blast 😎",
    },
  ],
  Worktother: [
    {
      number: "1",
      sentence: "Book A 15 Mins Call",
    },
    {
      number: "2",
      sentence: "Get a Healing Session",
    },
    {
      number: "3",
      sentence: "Be your own healer",
    },
  ],
  Anxietyhelp: [
    {
      Name: "Practice self-care that actually works",
      para: "So you can feel more calm, grounded and connected to yourself and others. ",
    },
    {
      Name: "Reality check those racing thoughts",
      para: "So you can take action in your life that feels productive instead of being distracted by the tsunami of thoughts overwhelming you.",
    },
    {
      Name: "Strengthen the mind-body connection",
      para: "So you can live from a place of deep inner wisdom, rather than just repeating the same self-sabotaging patterns. ",
    },
    {
      Name: "Take steps to heal self-doubt and criticism",
      para: "So that you can experience your life with more ease, joy and love.",
    },
  ],
  FooterRoutes: [
    {
      label: "Home",
      url: "/",
    },
    {
      label: "About",
      url: "/about",
    },
    {
      label: "Chakra Balancing Course",
      url: "/chakrabalancingcourse",
    },
    {
      label: "Testimonials",
      url: "/testimonial",
    },
    {
      label: "Fees",
      url: "/fee",
    },
    {
      label: "Contact",
      url: "/contact",
    },
  ],
  socialIcons: [
    {
      icon: <FaFacebookF />,
      url: "https://www.facebook.com/people/Anu-The-Therapist/61559614874476/",
      bgColor: "#3b5998",
    },
    {
      icon: <BsYoutube />,
      url: "https://www.youtube.com/@realtalkswithanu",
      bgColor: "#FF0000",
    },
    {
      icon: <FaInstagram />,
      url: "https://www.instagram.com/anuthetherapist/",
      bgColor: "#FF027E",
    },
    // {
    //   icon: <TiSocialLinkedin />,
    //   url: "https://www.instagram.com/anuthetherapist/",
    //   bgColor: "#0077B5",
    // },
  ],
  specialities: [
    {
      label: "Anxiety",
      url: "/anxiety",
    },
    {
      label: "Chronic Illness",
      url: "/chronic-illness",
    },
    {
      label: "Depression",
      url: "/depression",
    },
    {
      label: "Spiritual Blockages",
      url: "/spiritualityblockages",
    },
  ],
  ecardservices: [
    {
      list: [
        {
          icon: "⦿",
          label: "Past Life Regression",
        },

        {
          icon: "⦿",
          label: "Relationship Therapy ",
        },
        {
          icon: "⦿",
          label: "Inner Child Healing",
        },
        {
          icon: "⦿",
          label: "Space Clearing",
        },
        {
          icon: "⦿",
          label: "Heal Professional/Business/Money Blocks",
        },
        {
          icon: "⦿",
          label: "Akashic Record Reader",
        },

        {
          icon: "⦿",
          label:
            " Heart/Respiratory/Diabetes/Cancer/COVID etc. Any Diseases or Pains Can Be Healed",
        },
      ],
    },

    {
      list: [
        {
          icon: "⦿",
          label: "Build Immunity To Fight Disease ",
        },
        {
          icon: "⦿",
          label: "Reconnective Healing ",
        },
        {
          icon: "⦿",
          label: "Aura & Chakra Balance ",
        },
        {
          icon: "⦿",
          label: "Soul Healings ",
        },
        {
          icon: "⦿",
          label: "Thetha Healings/Reiki",
        },
        {
          icon: "⦿",
          label: "Miracle Method With Pendulum.",
        },
        {
          icon: "⦿",
          label: "Ancestral Healing",
        },
      ],
    },
  ],
  newData: [
    {
      heading: "Explore the impact of positive energy:",
      list: [
        {
          icon: "⦿", // Example icon
          label:
            "Every thought we think has an energetic frequency and has the potential to create our reality. Our mind can generate over 60,000 thoughts a day.",
        },
        {
          icon: "⦿", // Example icon
          label:
            "These thoughts transform into feelings, which are stored in our energy centres, known as Chakras. ",
        },
        {
          icon: "⦿", // Example icon
          label:
            "When our thoughts are balanced, our chakras spin in a clockwise direction and we feel energetically aligned and feel happy. ",
        },
        {
          icon: "⦿", // Example icon
          label:
            " To be in a state of Joy effortlessly, is our purpose of living our life! Happiness is a state of mind and is an internal experience.",
        },
      ],
    },
  ],
  beleiveData: [
    {
      heading: "Explore the impact of positive energy:",
      list: [
        {
          icon: "⦿", // Example icon
          label: "Book a 15-minute Call to discuss your needs.",
        },
        {
          icon: "⦿", // Example icon
          label: "Receive a Healing Session tailored for you.",
        },
        {
          icon: "⦿", // Example icon
          label: "Become Your Own Healer ",
        },
      ],
    },
  ],
};
export default Data;
