import React, { useEffect } from "react";
import Abtdepressionimg from "../../assets/img/banner/chronic-healer.jpg";
import Aboutspecialities from "../../component/aboutspecialities";
import wrktogtherstrip from "../../assets/img/elements/wrktogtherstrip.png";
import Healerimg from "../../assets/img/gallery/galleryimg3.png";
import Modalitieschronicimg from "../../assets/img/elements/Modalitieschronicimg.jpg";
import Aboutsection from "../../component/aboutsection";
import Modalities from "../../component/modal";
import Helpyou from "../../component/Helpyou";
import Singletestimonial from "../../component/singletestimonial";
import Worktogether from "../../component/wrktogether";
import Wrktogthrhlpyou from "../../component/worktogetherhlpyou";
import Contactsection from "../../component/contactformsection";
import "./index.css";
import pendulum from "../../assets/img/gallery/Client-pendulum.jpeg";
import Buttonredirct from "../../component/button-redirect";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
const Chronicillness = () => {
  useEffect(() => {
    // Scroll to the top when this component is mounted
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs once when the component mounts

  const phone = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <div className="container-fluid margintopall Chronicbgsection  px-0  ">
        <div className="conatctoverlay">
          <div className="container midpartbanner ">
            <div className="row">
              <div className="col-sm-8 text-center mx-auto">
                <div>
                  <h5 className="allbanerheading  my-4">
                    <strong>
                      Rediscover your power with wellness therapy for chronic
                      illness.
                      <br></br>
                      <br></br>
                      THERAPY FOR CHRONIC-ILLNESS CAN HELP
                    </strong>
                  </h5>
                </div>
                <div className="buttonparent text-center ">
                  <Buttonredirct
                    buttonname="Book A Consultation call"
                    buttonname2="Book A Consultation call"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Aboutspecialities
        img={Abtdepressionimg}
        heading="Chronic-Illness Therapy + Counseling in El Dorado Hills + Online"
        description="We’re so excited to introduce you to Ketamine Assisted Psychotherapy (KAP), a groundbreaking treatment that uses ketamine to enhance the psychotherapy experience. Ketamine therapy is currently the only legal psychedelic therapy treatment available in California. This innovative approach offers sustainable relief from lingering symptoms. "
        strong="Then you wake up one day and you can feel it."
        para="The days feel too long. You can't find the thread of joy you used to feel. You want to hide, disappear, be done. It just doesn't feel like life is ever going to get any better."
        anotherpara="Depression can carry you away. Into the depths of despair. Without caring what happens. Fearing no one else will care either. The voice is insidious, telling you no one cares, that there's no hope, no way out, why even bother?To step into this darkness takes great bravery."
        strong2="To step into this darkness takes great bravery. "
        para1="You need a roadmap back to yourself. A light on the path. A hand to hold in the darkness. "
        para2="I am here to help."
        para3="I will walk with you as you discover yourself again. "
        button="Book A Consultation call"
        button2="Book A Consultation call"
        stripimg={wrktogtherstrip}
      /> */}

      <div className="container my-5">
        <Grid
          container
          className=" innerpadding-all
"
          alignItems={"center"}
        >
          <Grid item lg={6}>
            <Typography
              variant="h3"
              sx={{
                fontSize: {
                  xs: 24,

                  lg: 30,
                },
              }}
              color="#03492f"
            >
              Chronic-Illness Therapy and Counseling – Online Sessions with Anu
              Kulkarni
            </Typography>
            <Box mt={3}></Box>

            <Typography variant="p">
              Anu Kulkarni’s chronic illness therapy provides a compassionate,
              holistic healing approach that nurtures both mind and body.
            </Typography>
            <br></br>
            <br></br>
            <Typography variant="p">
              Anu's integrative therapy combines hypnotherapy, spiritual
              counselling, and Reconnective Healing to release emotional
              blockages, deep stress, and physical discomfort, easing pain,
              anxiety, and exhaustion.
            </Typography>
            <br></br>
            <br></br>

            <Typography variant="p">
              As you progress, you’ll rediscover inner strength, find comfort in
              your body, and cultivate lasting peace. Anu’s guidance empowers
              you to restore balance, reconnect with your true self, and embrace
              a renewed sense of vitality.
            </Typography>
            <Box mt={3}></Box>
            <div className="buttonparent mt-4 ">
              <Buttonredirct
                buttonname="Book A Consultation Call"
                buttonname2="Book A Consultation Call"
              />
            </div>
          </Grid>
          <Grid item lg={6}>
            <img alt="" src={Abtdepressionimg} width="100%" />
          </Grid>
        </Grid>
        <Grid container className="innerpadding-all mt-5">
          <Grid item lg={12}>
            <Typography sx={{ fontWeight: 550 }}>
              When the Weight of Chronic Illness Becomes Overwhelming
            </Typography>

            <Typography variant="p">
              There are days when the heaviness of chronic illness feels
              unbearable. The days stretch endlessly, and finding moments of joy
              seems out of reach. It may feel like you want to retreat,
              disappear, and that life will never improve. The persistent
              struggle can make it seem as if there’s no hope or way forward.
            </Typography>
            <br />
            <br />
          </Grid>
          <Grid item lg={12}>
            <Typography sx={{ fontWeight: 550 }}>
              Navigating the Depths of Chronic Illness
            </Typography>

            <Typography variant="p">
              Facing this challenge requires immense courage. You deserve
              compassionate guidance and support to help you find your way back
              to balance and well-being. With Anu Kulkarni’s holistic approach,
              you’ll receive the care and direction needed to navigate the
              darkness and rediscover hope and healing.
            </Typography>
            <br />
            <br />
            <Typography variant="p">
              Depression can carry you away. Into the depths of despair. Without
              caring what happens. Fearing no one else will care either. The
              voice is insidious, telling you no one cares, that there's no
              hope, no way out, why even bother?To step into this darkness takes
              great bravery.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Aboutsection
        img={Healerimg}
        italichead="Hi, I’m Anu Therapist"
        subheading="Founder of the Holistic Counseling Center and Licensed Therapist"
        para2="You are whole, and healing is always possible. I'm here to walk alongside you on this journey."
        para3="With a focus on holistic therapy for chronic illness, I provide a nurturing, heart-centred approach that integrates the mind, body, and spirit. For the last twenty three years, I have integrated knowledge from psychology with spiritual insight to facilitate people in making contact with the deeper roots of their problems.  "
        para4="We can work together to free you from what holds you back and create a meaningful and durable change through which you can experience a life full of peace, vitality, and fulfilment. "
        button="BOOK A CONSULTATION CALL"
        button2="BOOK A CONSULTATION CALL"
      />
      {!phone && (
        <div className="container-fluid">
          <Modalities
            bgImage={Modalitieschronicimg}
            height={"100vh"}
            modalitieshead="Benefits of Chronic-Illness Therapy:"
            list1="Ease the emotional strain of chronic illness with compassionate support."
            // list2="Build effective strategies to manage daily challenges with resilience."
            list3="Find relief from discomfort through holistic and nurturing care."
            list4="Empower yourself to take charge of your health journey with confidence."
            list5="Build effective strategies to manage daily challenges with resilience."
            list6="Reduce dependence on addictive substances and activities such as food, screens and alcohol"
          />
          <div class="buttonparent text-center">
            <Buttonredirct
              buttonname="Book An Consultation Call"
              buttonname2="Book An Consultation Call"
            />
          </div>
        </div>
      )}
      <Helpyou
        heading="Chronic-Illness Therapy can help you heal!"
        paraheading="During your Chronic-Illness Treatments, we’ll help you:"
        name1="Renewed Vitality"
        para1="Reclaim your energy and zest for life with holistic healing techniques. "
        name2="Balanced Wellness"
        para2="Harmonize your mind, body, and spirit to better manage chronic conditions."
        name3="Personal Empowerment"
        para3="Unlock your inner strength and resilience to navigate your health journey with confidence."
      />
      <Singletestimonial
        para="Anu it’s all thanks to you . You gave me a lot of strength and courage by working on me so  deligently every day .
        That encouraged me to come for the wonderful workshop you had for us.
        Anu always be blessed."
        name="Simren s."
      />
      <Worktogether />
      <div className=" Chronicbg-section"></div>
      <div className="container-fluid ">
        <Wrktogthrhlpyou
          heading="uplift your life with chronic illness therapy:"
          list1="Transform deep-seated wounds and release past pain for profound healing."
          list2="Access hidden memories to gain insight and achieve lasting emotional growth."
          list3="Alleviate persistent symptoms of depression and anxiety for relief."
          list4="Deepen your spiritual connection and embrace a more fulfilling life."
          list5="Rewire your mind to shift from fear and uncertainty to a state of love and connection."
          subheading="If you are looking for Chronicillness Therapy, please reach out."
          strong="I look forward to connecting!"
          sign="xo Anu"
          button="Make An Appointment"
          button2="Make An Appointment"
        />
        <Contactsection
          img={pendulum}
          heading="Set Up Your Online Session for Chronicillness Therapy"
          para="Are you ready to explore the transformative power of holistic psychotherapy and how it can enhance your journey? Let’s connect for a complimentary consultation call. Click below to schedule, and we’ll discuss how this healing path can support your growth. If it resonates, we’ll arrange your first online session. "
          para2="Have questions or need more information? Feel free to call or text at anuakatalyst@gmail.com, or use the contact form to reach out. I look forward to guiding you toward inner harmony and transformation.
"
          button="Make an Appointment"
          button2="Make an Appointment"
        />
      </div>
    </div>
  );
};

export default Chronicillness;
