import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Data from "../../assets/data";
import "./index.css";
import pendulum from "../../assets/img/gallery/galleryimg2.jpg";
import { MdStarRate } from "react-icons/md";
import Buttonredirct from "../../component/button-redirect";
import { Typography } from "@mui/material";
import Contactsection from "../../component/contactformsection";
const Testimonial = () => {
  useEffect(() => {
    // Scroll to the top when this component is mounted
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs once when the component mounts
  return (
    <div>
      <div className="container-fluid margintopall testimonialpagebg  px-0  ">
        <div className="overlaybanner">
          <div className="container midpartbanner ">
            <Typography
              variant="h3"
              className="Navpagesheading"
              textAlign={"center"}
            >
              Real Stories of Healing and Transformation
            </Typography>
            <Typography
              variant="h4"
              className="Navpagessubheading"
              mt={5}
              textAlign={"center"}
            >
              What People are saying
            </Typography>
          </div>
        </div>
      </div>
      <div className="container bigwraper  text-center">
        {/* <h4>What People are saying </h4> */}
        <div className="row  innerpadding-all">
          {Data.testimonialpage.map((val, id) => (
            <div className="col-sm-4 mt-4" key={id}>
              <div>
                <p>
                  <MdStarRate />
                  <MdStarRate />
                  <MdStarRate />
                  <MdStarRate />
                  <MdStarRate />
                </p>
                <p>"{val.para}"</p>
              </div>
              <p className="username">{val.name}</p>
            </div>
          ))}
        </div>
      </div>

      <Contactsection
        img={pendulum}
        heading="Ready to Begin Your Healing Journey?"
        para="There’s no better time than now to start your healing process. Click below to book your session,"
        paraa="or reach out at "
        mail="anuakatalyst@gmail.com"
        continuepara="for more details. I look forward to connecting with you and supporting your journey toward inner harmony and transformation."
        button="MAKE AN APPOINTMENT"
        button2="MAKE AN APPOINTMENT"
      />
    </div>
  );
};

export default Testimonial;
