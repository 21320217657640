import React, { useEffect } from "react";
import Abtdepressionimg from "../../assets/img/banner/depression-healer.jpg";
import Modalitiesdepressionimg from "../../assets/img/elements/Modalitiesdepressionimg.jpg";
import wrktogtherstrip from "../../assets/img/elements/wrktogtherstrip.png";
import pendulum from "../../assets/img/gallery/Client-pendulum.jpeg";
import Healerimg from "../../assets/img/gallery/galleryimg3.png";
import Aboutsection from "../../component/aboutsection";
import Aboutspecialities from "../../component/aboutspecialities";
import Buttonredirct from "../../component/button-redirect";
import Contactsection from "../../component/contactformsection";
import Helpyou from "../../component/Helpyou";
import Modalities from "../../component/modal";
import Singletestimonial from "../../component/singletestimonial";
import Wrktogthrhlpyou from "../../component/worktogetherhlpyou";
import Worktogether from "../../component/wrktogether";
import "./index.css";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
const Depression = () => {
  const phone = useMediaQuery("(max-width:600px)");
  useEffect(() => {
    // Scroll to the top when this component is mounted
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs once when the component mounts
  return (
    <div>
      <div className="container-fluid margintopall Depressionpagebg  px-0  ">
        <div className="conatctoverlay">
          <div className="container midpartbanner ">
            <div className="row">
              <div className="col-sm-8 text-center mx-auto">
                <div>
                  <h5 className="allbanerheading  my-4">
                    <strong>
                      You are here for a reason. Your reason is to live. Find
                      your strength and burn like a bright star.
                      <br></br>
                      <br></br>
                      Therapy For Depression Can Help!
                    </strong>
                  </h5>
                </div>
                <div className="buttonparent text-center ">
                  <Buttonredirct
                    buttonname="Book A Consultation Call"
                    buttonname2="Book A Consultation Call"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Aboutspecialities
        img={Abtdepressionimg}
        heading="Depression Therapy + Counseling in El Dorado Hills + Online"
        description="You could see a black cloud following you in the beginning. You let it be there, you let it grow. Until, not realizing when it made you its only home, because time went away in sand."
        strong="It was too gradual."
        para=" You no more remember how it began. Only that suddenly, you’re hearing yourself crash."
        anotherpara="You’re missing the joy out of life. Your emotions can’t be sensed anymore, you’re too numb. You don’t find it gratifying to do things anymore. Everything is just a big black cloud. Your judgment too gets clouded. You can't find the motivation to crawl out of bed now. It is tiresome and you’re losing hope. You’re sinking. That is all you know.You just want to hide, go away, disappear. Not exist."
        strong2="Your depression is a trickster. It lies to you that nobody cares and nothing matters. Making you feel hopeless in finding a point within bravery of stepping out of this."
        para1="You still can. You can find your path back to joy. To a fulfilling life."
        para2="I am here to help."
        para3="We’ll walk together."
        button="Book A Consultation call"
        button2="Book A Consultation call"
        stripimg={wrktogtherstrip}
      /> */}

      <div className="container bigwraper">
        <Grid container className="innerpadding-all" alignItems={"center"}>
          <Grid item lg={6}>
            <Typography
              color={"#03492f"}
              sx={{
                fontSize: {
                  xs: 24,

                  lg: 30,
                },
              }}
              variant="h3"
            >
              Depression Therapy and Counseling Online with Anu Kulkarni
            </Typography>
            <br />
            <Typography variant="p" lineHeight={1.2}>
              At first, it’s just a small black cloud in the distance. Slowly,
              it grows and settles into your life, stealing joy and light as
              time slips away.
            </Typography>
            <br></br>
            <br></br>
            <Typography variant="p" lineHeight={1.2}>
              But you don't have to carry this alone. With compassionate
              therapy, you can lift the weight, heal from within, and find your
              way back to peace. Let me guide you on this journey to reclaim
              your strength and happiness.
            </Typography>
            <Box mt={3}>
              <div className="buttonparent mt-4 ">
                <Buttonredirct
                  buttonname="Book A Consultation Call"
                  buttonname2="Book A Consultation Call"
                />
              </div>
            </Box>
          </Grid>
          <Grid item lg={6}>
            <img alt="" src={Abtdepressionimg} width={"100%"} />
          </Grid>
        </Grid>
        <Grid container className="innerpadding-all">
          <Grid item lg={12}>
            <Typography fontWeight={550}>It was too gradual.</Typography>
            <br />
            <Typography variant="p">
              You no more remember how it began. Only that suddenly, you’re
              hearing yourself crash.
            </Typography>
            <br />
            <Typography variant="p">
              You’re missing the joy out of life. Your emotions can’t be sensed
              anymore, you’re too numb. You don’t find it gratifying to do
              things anymore. Everything is just a big black cloud. Your
              judgment too gets clouded. You can't find the motivation to crawl
              out of bed now. It is tiresome and you’re losing hope. You’re
              sinking. That is all you know.You just want to hide, go away,
              disappear. Not exist.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Aboutsection
        img={Healerimg}
        headinglarge="Hi, I’m Anu Kulkarni, offering online therapy for emotional, mental, and spiritual healing."
        para2="What you are going through, it  is not a life sentence. Change and healing is possible. Emancipation is within you."
        para3="As a holistic spiritual therapist, I offer you restoration of your spiritual and physical balance with a heart-centric approach, through energy work."
        para4="For over 23+ years, I have been working towards empowering minds, in the wake of new world interconnectedness. I know the resolve. I know how to help you discover the root cause of your depression, and create a lasting change to live life to its fullest potential."
        button="BOOK A CONSULTATION CALL"
        button2="BOOK A CONSULTATION CALL"
      />
      <div className="container">
        <div className="row">
          <div className="col-sm-10 mx-auto ">
            <img className="w-100" src={wrktogtherstrip}></img>
            <h6 className="subheadings text-center">
              I help people just like you, struggling with:
            </h6>

            <div className="row ">
              <div className="col-sm-6">
                <ul className="Specialitieslist">
                  <li>
                    <p>Constant sadness or emptiness.</p>
                  </li>
                  <li>
                    <p>Loss of interest in favorite activities.</p>
                  </li>
                  <li>
                    <p>Fatigue and low energy.</p>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 ">
                <ul className="Specialitieslist">
                  <li>
                    <p>Trouble focusing or making decisions.</p>
                  </li>
                  <li>
                    <p>Sleep disturbances.</p>
                  </li>
                  <li>
                    <p>Hopelessness or feeling worthless.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!phone && (
        <div className="container-fluid">
          <Modalities
            bgImage={Modalitiesdepressionimg}
            height={"100vh"}
            modalitieshead="Benefits of Depression Therapy"
            list1="Restore emotional balance and stability."
            // list2="Develop healthy coping strategies."
            list3="Improve self-esteem and confidence."
            list4="Enhance overall well-being and happiness."
            list5="Develop healthy coping strategies."
            // list6="No interest in experiencing pleasure"
          />
          <div class="buttonparent text-center">
            <Buttonredirct
              buttonname="Book A Consultation Call"
              buttonname2="Book A Consultation Call"
            />
          </div>
        </div>
      )}
      <Helpyou
        heading="There’s hope and help available for what you’re going through. Therapy for Depression can empower you to:"
        paraheading="Therapy For Depression Can Help"
        name1="Practice Self-care and Nurture"
        para1="Allow yourself to reconnect with hope from within. Find the motivation and inspiration to live again."
        name2="Reconnect the mind with the body"
        para2="Break away from self-sabotaging patterns and unhealthy coping. Learn of your inner wisdom and how to use it."
        name3="Remove Self-Doubt and Inner critic"
        para3="Trust your actions and thoughts more carefully. Find the validity of reason towards self."
      />
      <Singletestimonial
        para="Anu it’s all thanks to you . You gave me a lot of strength and courage by working on me so  deligently every day .
        That encouraged me to come for the wonderful workshop you had for us.
        Anu always be blessed."
        name="Rohit k."
      />
      <Worktogether />
      <div className=" Depsbbgsection "></div>
      <div className="container-fluid ">
        <Wrktogthrhlpyou
          heading="Heal wounds and find inner peace."
          list1="Cultivate safety and security by rewiring your mind, and easing fear and uncertainty."
          list2="Harness inner wisdom and strengthen intuition for life’s guidance."
          list3="Deepen self-trust and embrace self-love with therapeutic support."
          list4="Reconnect with your emotions and uncover hidden feelings."
          list5="Break free from recurring challenges and emotional patterns."
          subheading="You are not broken. Healing and change is always possible."
          strong="I look forward to connecting!"
          sign="xo Anu"
          button="Make An Appointment"
          button2="Make An Appointment"
        />
      </div>
      <Contactsection
        img={pendulum}
        heading="Set Up Your Online Session for Depression Therapy"
        para="Are you ready to explore the transformative power of holistic psychotherapy and how it can enhance your journey? Let’s connect for a complimentary consultation call. Click below to schedule, and we’ll discuss how this healing path can support your growth. If it resonates, we’ll arrange your first online session. "
        para2="Have questions or need more information? Feel free to call or text at anuakatalyst@gmail.com, or use the contact form to reach out. I look forward to guiding you toward inner harmony and transformation.
"
        button="Make an Appointment"
        button2="Make an Appointment"
      />
    </div>
  );
};

export default Depression;
