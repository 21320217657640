import React, { useEffect } from "react";
import "./index.css";

import pendulum from "../../assets/img/gallery/galleryimg2.jpg";
import Healerimg from "../../assets/img/gallery/galleryimg3.png";

import AOS from "aos";
import "aos/dist/aos.css";
import Data from "../../assets/data";
import Aboutsection from "../../component/aboutsection";
import Believe from "../../component/Believe";
import Buttonredirct from "../../component/button-redirect";
import Contactsection from "../../component/contactformsection";
import Testimonialsection from "../../component/testimonialsection";
import Worktogether from "../../component/wrktogether";
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import image from "../../assets/img/banner/bg-healer.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { MdNavigateNext } from "react-icons/md";
import { IoChevronBackSharp } from "react-icons/io5";
import Ecard from "../../component/e-card";

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const handleNavLinkClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 30,
    height: 30,
    cursor: "pointer",
  };
  const data = [
    {
      heading: "I help people just like you, struggling with:",
      list: [
        {
          icon: "⦿",
          label:
            "Heal from the inside out, overcome life’s hurdles, and rise above challenges to experience a life filled with harmony and happiness",
          boldword: "ALL WAYS.",
        },
        {
          icon: "⦿",
          label:
            "Let go of past traumas and life blockages that are weighing you down. ",
        },
        {
          icon: "⦿",
          label:
            "Heal and improve your relationships with parents, partners, colleagues etc.",
        },
        {
          icon: "⦿",
          label: "Clear energy blockages to help you grow and thrive.",
        },
        {
          icon: "⦿",
          label:
            "Release old beliefs and patterns through gentle soul healings.",
        },
      ],
    },
  ];

  return (
    <div>
      <div className="container-fluid homepage p-0">
        <div className="">
          <div className="container">
            <section className="row ">
              <div className=" col-sm-12  homebgoverlay homepageallcon ">
                <div className=" spacebanheading  ">
                  <div className="">
                    <h2 className="bannerheading ">
                      Change is the only constant...
                    </h2>
                    <h2 className="bannerheading2 ">Heal Evolve Thrive</h2>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      {/* about-site */}
      <div className="px-0 pt-0 worktogetherbg  ">
        <div className="aboutbg bigwraper ">
          <div className="container txtcenter    ">
            <section className="row " id="Aboutsection">
              <div className="col-sm-8 mx-auto">
                {/* <h4 className="heading-color">Hello and Welcome </h4> */}
                <Typography variant="h4" textAlign={"center"} color="#034929">
                  Some surprising facts:
                </Typography>
                <Box className="mb-3 ">
                  {Data.newData.map((val, i) => (
                    <Box key={i} px={{ lg: 0, xs: 0 }} mt={2}>
                      <List>
                        {val.list.map((item, id) => (
                          <ListItem key={id} className="listitempading">
                            <ListItemAvatar className="golddots">
                              {item.icon}
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="p"
                                  fontSize={{ xs: 13, lg: 15 }}
                                >
                                  {item.label}
                                </Typography>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  ))}
                </Box>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Ecard></Ecard>

      {/* banner start  */}

      <div className="container-fluid bigwraper px-0 banner_bg">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <Box>
                <Typography
                  fontWeight={400}
                  mb={3}
                  color={"#034929"}
                  variant="h4"
                >
                  Shift Your Mindset
                </Typography>

                <div className="col-sm-8 mx-auto">
                  {data.map((val, i) => (
                    <Box key={i} px={{ lg: 0, xs: 0 }}>
                      <List px={{ lg: 3, xs: 2 }}>
                        {val.list.map((value, id) => (
                          <ListItem className="listitempading" key={id}>
                            <ListItemAvatar className="golddots">
                              {value.icon}
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="p"
                                  fontSize={{ xs: 13, lg: 15 }}
                                >
                                  {value.label} <b>{value.boldword}</b>
                                </Typography>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  ))}
                </div>
              </Box>
              {/* </Carousel> */}
            </div>
          </div>
        </div>
      </div>

      {/* banner end  */}

      <div className="container bigwraper pb-5  itsallabtyoubg ">
        <Grid className="" container>
          <Grid item lg={6} className="whatifcol innerpadding-all pe-4 ">
            <Typography
              variant="h3"
              sx={{
                fontSize: {
                  xs: 23,

                  lg: 30,
                },
              }}
              fontSize={30}
            >
              When you change your mindset, everything around you will also
              change.
            </Typography>
            <Typography>
              It’s all about{" "}
              <Typography variant="span" fontWeight={600}>
                you
              </Typography>
              . <br />
            </Typography>

            <Typography mt={3} fontSize={15}>
              Peace of mind and exponential growth effortlessly. When you
              enhance your vibrations to receive more, miracles manifest with
              synchronicity and inner guidance opening doors to growth and
              expansion at all levels for you!
            </Typography>
            <Typography mt={3} fontSize={15}>
              Ready for the change?
            </Typography>

            <Box className="buttonparent mt-auto " sx={{ my: 3 }}>
              <Buttonredirct
                buttonname="Book A Consultation Call"
                buttonname2="Book A Consultation Call"
              />
            </Box>
          </Grid>
          <Grid item lg={6} className="innerpadding-all">
            <img src={image} width="100%" alt="" />
          </Grid>
        </Grid>
      </div>

      <Testimonialsection />

      <div className="container-fluid px-0 bigwraper  ">
        <Aboutsection
          img={Healerimg}
          italichead={"I Am Here for you….. "}
          strongpara="Gently guiding you towards profound inner healing and transformation."
          para2="Just as we go to the gym to strengthen our bodies, nurturing emotional strength is essential for a fulfilling life. Our inner world deserves as much attention as our outer appearance. If you're here, this journey is meant for you."
          para3="Let me help you unlock your hidden potential and achieve a state of blissful healing. All it takes is your desire to step forward and embrace change."
          para8="A little about me"
          para9="I share this with you because my journey began at 18, seeded by the wisdom of my parents. The study of Indian philosophy, Christian schooling, and my exposure of diverse cultures have continuously helped me reinvent and enrich my life. Through constant learning and various healing modalities, I've grown and evolved  to be confident enough to help others overcome their blockages to reinvent themselves and live life to its highest potential abundantly at all levels!"
        />
      </div>

      {/* end about Healer */}
      {/* testimonialsection */}

      {/* end testimonial section */}
      {/* <Worktogether /> */}
      {/* <Believe /> */}
      <Contactsection
        img={pendulum}
        heading="Ready to Begin Your Healing Journey?"
        para="There’s no better time than now to start your healing process. Click below to book your session,"
        paraa="or reach out at "
        mail="anuakatalyst@gmail.com"
        continuepara="for more details. I look forward to connecting with you and supporting your journey toward inner harmony and transformation."
        button="MAKE AN APPOINTMENT"
        button2="MAKE AN APPOINTMENT"
      />
    </div>
  );
};

export default Home;
