import React from "react";
import "./index.css";
import Form from "../form";
import Buttonredirct from "../button-redirect";
import { useMediaQuery } from "@mui/material";
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import Data from "../../assets/data";

const Contactsection = (props) => {
  const phone = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <div className="container-fluid bigwraper  abouthealerbg">
        <div className="container">
          <div className="row">
            <div className="col-sm-12  ">
              <div className="row align-items-center">
                <div className="col-sm-7 text-justify  ">
                  <div className="">
                    <h5 className="title2 heading-color">{props.heading}</h5>
                    <Box className="mb-3 mx-auto text-center">
                      {Data.beleiveData.map((val, i) => (
                        <Box key={i} px={{ lg: 0, xs: 0 }} mt={2}>
                          <List>
                            {val.list.map((item, id) => (
                              <ListItem
                                key={id}
                                className="listitempading ps-0"
                              >
                                <ListItemAvatar className="golddots">
                                  {item.icon}
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="p"
                                      fontSize={{ xs: 13, lg: 15 }}
                                    >
                                      {item.label}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      ))}
                    </Box>
                    <p className="appointmentp">
                      {props.para}
                      <br></br>

                      <span>
                        {props.paraa}
                        <strong>
                          <a
                            href="mailto:anuakatalyst@gmail.com"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            {props.mail}{" "}
                          </a>
                        </strong>
                      </span>
                      <span>{props.continuepara}</span>
                    </p>
                    <p>{props.para2}</p>
                    <div className="buttonparent mt-5">
                      <Buttonredirct
                        buttonname={props.button}
                        buttonname2={props.button2}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-5 appointmentimgparent ">
                  <img
                    src={props.img}
                    className={` ${phone && "mt-4"} appointmentimg`}
                    alt=""
                    height="100%"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactsection;
